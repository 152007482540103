import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{textAlign: "left"}}>
            Hi Everyone, I am <span className="purple">Abhishek Srivastava </span>
            from <span className="purple"> Varanasi, India.</span>
            <br />
            <br /> 
            I am pursuing my <span className="purple"> Master of Computer Applications (MCA) (2024-2026)</span> from  <span className="purple">Lovely Professional University</span> Punjab. It is a significant accomplishment, and I am proud of my achievement.
            <br />
            <br /> 
            As I am pursuing an MCA (2024-2026), I am enhancing my technical skills through this master's course while working professionally as a Front End Developer. I am proficient in HTML, CSS, JavaScript, React, jQuery, and Bootstrap. I have implemented AA-level accessibility to ensure the UI is fully responsive and cross-browser compatible. My skills also include media queries, Agile Scrum, manual testing, Handlebars, and Backbone.js. Additionally, I have developed e-learning courses using the Adapt framework. I am adept at troubleshooting and debugging in JS and JSX. This knowledge is invaluable for my career in  software engineering, application development, database administration, IT consulting, or research and development.
            <br />
            <br />
             With <span className="purple">1.5</span> years of experience in the IT sector, I have honed my skills as a <span className="purple">Front End Developer</span>, specializing in JavaScript and React.js.I've had the privilege of working on projects for esteemed clients such as <span className="purple">WHO, Qatar Airways, Abbott, AbbVie, MetLife, Unilever</span>, and many others. This experience has equipped me with a deep understanding of these technologies, enabling me to develop robust and scalable applications.I am Working in the IT sector for a service-based company has allowed me to refine my skills further. As a self-starter, quick learner, and problem-solver, I have a proven track record of delivering high-quality work within deadlines.
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Gathering information about Sci-Fi tech
            </li>
            <li className="about-activity">
              <ImPointRight /> Being Nerd about Technology and VFX
            </li>
            <li className="about-activity">
              <ImPointRight /> Listening Songs
            </li>
            <li className="about-activity">
              <ImPointRight /> Learning New Skills 
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Being young I am eager to learn new Technologies"{" "}
          </p>
          <footer className="blockquote-footer">Abhishek</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
