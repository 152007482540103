// import React, { useEffect, useState } from "react";
// import "./DarkMode.css";
// import { ReactComponent as Moon } from "./Moon.svg";
// import { ReactComponent as Sun } from "./Sun.svg";

// const DarkMode = () => {
//     // State to track current mode (dark or light)
//     const [isDarkMode, setIsDarkMode] = useState(true);

//     useEffect(() => {
//         // Set initial theme when the component mounts
//         updateTheme();
//     }, );

//     // Function to update the theme based on current mode
//     const updateTheme = () => {
//         const theme = isDarkMode ? 'dark' : 'light';
//         document.querySelector("body").setAttribute('data-theme', theme);
//     }

//     // Function to toggle between dark and light mode
//     const toggleTheme = () => {
//         setIsDarkMode(!isDarkMode); // Toggle mode
//     }

//     // Call updateTheme whenever mode changes
//     useEffect(() => {
//         updateTheme();
//     }, );

//     return (
//         <div className='dark_mode'>
//             <input
//                 className='dark_mode_input'
//                 type='checkbox'
//                 id='darkmode-toggle'
//                 checked={isDarkMode} // Reflect current mode state
//                 onChange={toggleTheme}
//             />
//             <label className='dark_mode_label' htmlFor='darkmode-toggle'>
//                 <Moon />
//                 <Sun />
//             </label>
//         </div>
//     );
// };

// export default DarkMode;



import React, { useEffect, useState } from "react";
import "./DarkMode.css";
import { ReactComponent as Moon } from "./Moon.svg";
import { ReactComponent as Sun } from "./Sun.svg";

const DarkMode = () => {
    // State to track current mode (dark or light)
    const [isDarkMode, setIsDarkMode] = useState(() => {
        // Get theme preference from local storage or default to dark mode
        return localStorage.getItem("theme") === "dark";
    });

    useEffect(() => {
        // Set initial theme when the component mounts
        updateTheme();
    },);

    // Function to update the theme based on current mode
    const updateTheme = () => {
        const theme = isDarkMode ? "dark" : "light";
        document.querySelector("body").setAttribute("data-theme", theme);
        // Store theme preference in local storage
        localStorage.setItem("theme", theme);
    };

    // Function to toggle between dark and light mode
    const toggleTheme = () => {
        setIsDarkMode(!isDarkMode); // Toggle mode
    };

    // Call updateTheme whenever mode changes
    useEffect(() => {
        updateTheme();
    },);

    return (
        <div className="dark_mode">
            <input
                className="dark_mode_input"
                type="checkbox"
                id="darkmode-toggle"
                checked={isDarkMode} // Reflect current mode state
                onChange={toggleTheme}
            />
            <label className="dark_mode_label" htmlFor="darkmode-toggle">
                <Moon />
                <Sun />
            </label>
        </div>
    );
};

export default DarkMode;
