import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import chatApp from "../../Assets/Projects/ticktack.png";
import expense from "../../Assets/Projects/react responsive.png";
import hubnex from "../../Assets/Projects/weather.png";
import notes from "../../Assets/Projects/expense.png";
import opinion from "../../Assets/Projects/stellar.png";
import QUEUES from "../../Assets/Projects/user-age.png";
import sidtech from "../../Assets/Projects/lion.png";
import todo from "../../Assets/Projects/investment.png";
import pooja from "../../Assets/Projects/haber.png";
import Loader from "../Loader/Loader1";

import Particle from "../Particle";
import ProjectCard from "./ProjectCards";

// import foodorder from "../../Assets/Projects/food-order.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }} className="quote-card-view">
          Here are a few projects I've worked on recently.
        </p>
        <Loader />
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={hubnex}
              isBlog={false}
              title={<span className="purple">Weather App</span>}
              description="Welcome to the Weather Dashboard project! This React application offers an interactive platform where users can search for and view current weather information by city name. The app features a user-friendly interface with an intuitive and responsive design, ensuring easy navigation. Users can quickly find weather details for any city using the search bar. The dashboard displays up-to-date weather conditions, including temperature, humidity, wind speed, and more. Additionally, weather icons provide visual representations of the current weather conditions for clear understanding. The application also includes error handling to alert users in case of invalid city names or failed data retrieval. This app is ideal for anyone needing quick access to weather updates in various locations."
              ghLink="https://github.com/Abhishek143895/weather_app"
              demoLink="https://weatherapp-wheat-chi.vercel.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={sidtech}
              isBlog={false}
              title={<span className="purple">WildVerse Exploring Earths Natural Symphony</span>}
              description={
              `Welcome to WildVerse, a captivating platform dedicated to exploring the wonders of wildlife through immersive storytelling and stunning visuals. It provides insights into the development process, technologies used, and sources referenced. In the development of WildVerse, a combination of HTML, CSS, and JavaScript was employed to create a seamless user experience. The website is designed to be fully responsive, ensuring a consistent and engaging user experience across all devices, from desktops to mobile phones. Smooth animations enhance the storytelling experience, making interactions more engaging and visually appealing. Full High Definition (FHD) graphics provide stunning visuals that bring the beauty of wildlife to life. WildVerse combines these elements to deliver an immersive and seamless user experience, allowing users to explore the wonders of wildlife in an engaging and visually rich environment.`}
              ghLink="https://github.com/Abhishek143895/WildVerse-Exploring-Earth-s-Natural-Symphony"
              demoLink="https://abhishek143895.github.io/WildVerse-Exploring-Earth-s-Natural-Symphony/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={opinion}
              isBlog={false}
              title={<span className="purple">Data Rescue Pro: Your Ultimate Data Recovery Solution</span>}
              description={`Welcome to Data Rescue Pro, your ultimate solution for comprehensive data recovery services. Whether you need to recover lost files from SD cards, desktop hard drives, pen drives, MacBooks, or any other storage device, we've got you covered. Our platform combines advanced recovery techniques with a seamless user experience to ensure you get your valuable data back quickly and efficiently. Data Rescue Pro is designed with responsiveness and cross-browser compatibility in mind, providing a consistent and engaging experience across all devices and browsers. Enjoy smooth animations as you scroll through our site, adding an interactive and visually appealing touch to your browsing experience. Our intuitive carousel feature highlights key services and testimonials, making it easy for you to navigate and find the information you need. We employ state-of-the-art technology and expert techniques to recover data from various storage devices, ensuring the highest success rates possible. Trust Data Rescue Pro to handle your data recovery needs with professionalism and care. Explore our services today and let us help you retrieve your lost data effortlessly.`}
              ghLink="https://github.com/Abhishek143895/Stellar"
              demoLink="https://stellar-data-recovery.netlify.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatApp}
              isBlog={false}
              title={<span className="purple">
              Tic-Tac-Toe Challenge: Play, Compete, Conquer!</span>}
              description={
                `Introducing my Tic-Tac-Toe game, a responsive and straightforward web application crafted with React.js. This game offers an interactive platform where two players can engage in friendly competition on a single screen. With its responsive design, players can enjoy a seamless experience across various devices. Every click on the game board is instantly updated, allowing players to see each other's moves in real-time. At the end of each game, the application displays the winner, or indicates if the game ends in a draw, making for an engaging and enjoyable gaming experience for all.`}
              ghLink="https://github.com/Abhishek143895/TickTackGameReact"
              demoLink="https://inspiring-stardust-ea2230.netlify.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={notes}
              isBlog={false}
              title={<span className="purple">Expense Tracker App
              </span>}
              description={`Introducing my Expense Tracker web application, meticulously crafted using React and JavaScript. This handy tool simplifies expense management by allowing users to effortlessly track their daily, monthly, or weekly expenditures. With features to add expenses including date and title, users can easily monitor their spending habits. Additionally, the app provides insightful graphical representations, offering a visual overview of expenditure patterns over time. Streamline your financial management with this intuitive Expense Tracker and gain better control over your finances.`}
              ghLink="https://github.com/Abhishek143895/Expense_Tracker_APP"
              demoLink="https://main--venerable-toffee-cb801a.netlify.app/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={todo}
              isBlog={false}
              title={<span className="purple">Investment Calculator</span>}
              description={`Introducing my Investment Calculator, a powerful tool developed with React.js to aid in financial planning and decision-making. This intuitive calculator empowers users to estimate potential returns on their investments with ease. Whether it's calculating interest, evaluating different investment scenarios, or forecasting future growth, this tool provides valuable insights. With its user-friendly interface, users can input variables such as initial investment amount, interest rate, and investment period, and instantly visualize the potential outcomes. Make informed investment decisions and plan for a financially secure future with this versatile Investment Calculator.`}
              ghLink="https://github.com/Abhishek143895/InvestmentCalculatorReact"
              demoLink="https://main--curious-sorbet-2359be.netlify.app/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={expense}
              isBlog={false}
              title={<span className="purple">React Essentials</span>}
              description={`Welcome to my Responsive React Web App, designed to introduce you to the fundamental concepts of JSX, props, state, and components. In this app, you'll discover the power and simplicity of JSX, a syntax extension for JavaScript that allows for seamless integration of HTML-like code within React components. Learn how props enable the passing of data from parent to child components, facilitating dynamic and reusable code. Explore the concept of state, which enables components to manage their own data, leading to dynamic UI updates and interactive user experiences. Finally, delve into the world of components, the building blocks of React applications, allowing for modular and scalable development. With its responsive design, this app ensures a seamless learning experience across various devices. Dive into the world of React and master these essential concepts with my Responsive React Web App.`}
              ghLink="https://github.com/Abhishek143895/responsive_react_app"
              demoLink="https://grand-frangollo-a661dc.netlify.app/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={pooja}
              isBlog={false}
              title={<span className="purple">Investment Company: Your Path to Financial Growth</span>}
              description="Explore the world of investment with Haber, where we demystify the basics of investing to empower you towards financial success. Through our responsive web app, gain insights into essential investment principles, including risk management, portfolio diversification, and asset allocation.Discover the various investment options available, from stocks and bonds to mutual funds and real estate. Learn how to assess investment opportunities, evaluate potential risks and returns, and make informed decisions tailored to your financial goals.With Haber, embark on a journey towards financial independence and security. Our intuitive web app makes learning about investments easy and accessible, whether you're on your desktop, tablet, or smartphone.Empower yourself with the knowledge and tools you need to navigate the complex world of finance confidently. Start your investment journey with Haber today and unlock a brighter financial future."
              ghLink="https://github.com/Abhishek143895/Newgen_project"
              demoLink="https://abhishek143895.github.io/Newgen_project/"
            />
          </Col> 
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={QUEUES}
              isBlog={false}
              title={<span className="purple">AgeKeeper</span>}
              description="AgeKeeper is a simple React web application designed to store user information such as names and ages. The application features an input form where users can enter their name and age, and upon clicking the Add button, the information is dynamically added to a list displayed on the same page. This allows users to see all entered data in real-time and continue adding as many entries as needed. The application showcases the basics of state management and dynamic rendering in React, making it an excellent starting point for those learning React."
              ghLink="https://github.com/Abhishek143895/User_Age"
              demoLink="https://whimsical-unicorn-e5548b.netlify.app/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
