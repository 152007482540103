import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import {
  AiOutlineFundProjectionScreen,
  AiOutlineHome
} from "react-icons/ai";
import { CgFileDocument } from "react-icons/cg";
import { GiSkills } from "react-icons/gi";
import { MdCollectionsBookmark, MdContacts, MdPublic } from "react-icons/md";
import { Link } from "react-router-dom";
import logo from "../Assets/logo.svg";
import DarkMode from "./DarkMode/DarkMode";

function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}
    >
      <Container>
        <Navbar.Brand href="/" className="d-flex">
          <img src={logo} className="img-fluid logo logo-phone" alt="brand" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto" defaultActiveKey="#home">
            <Nav.Item >
              <Nav.Link as={Link} to="/" onClick={() => updateExpanded(false)} >
                
                <AiOutlineHome style={{ marginBottom: "2px" }} className="navbar_font"/><span className="navbar_font" style={{ marginLeft: '5px' }}>Home</span>
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/about"
                onClick={() => updateExpanded(false)}
              >
                <GiSkills style={{ marginBottom: "2px" }} className="navbar_font"/> <span className="navbar_font" style={{ marginLeft: '5px' }}>Skillset</span>
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/project"
                onClick={() => updateExpanded(false)}
              >
                <AiOutlineFundProjectionScreen
                  style={{ marginBottom: "2px" }}
                  className="navbar_font"
                />{" "}
                <span className="navbar_font" style={{ marginLeft: '5px' }}>Projects</span>
              </Nav.Link>
            </Nav.Item>



            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/blogs"
                onClick={() => updateExpanded(false)}
              >
                <MdCollectionsBookmark
                  style={{ marginBottom: "2px" }}
                  className="navbar_font"
                />{" "}
                <span className="navbar_font" style={{ marginLeft: '5px' }}>Blogs</span>
              </Nav.Link>
            </Nav.Item>

            {/* <Nav.Item>
              <Nav.Link
                as={Link}
                to="/news"
                onClick={() => updateExpanded(false)}
              >
                <AiOutlineFundProjectionScreen
                  style={{ marginBottom: "2px" }}
                />{" "}
                News
              </Nav.Link>
            </Nav.Item> */}

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/latestnews"
                onClick={() => updateExpanded(false)}
              >
                <MdPublic
                  style={{ marginBottom: "2px" }}
                  className="navbar_font"
                />{" "}
                <span className="navbar_font" style={{ marginLeft: '5px' }}>Daily News</span>
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/resume"
                onClick={() => updateExpanded(false)}
              >
                <CgFileDocument style={{ marginBottom: "2px" }} className="navbar_font"/> <span className="navbar_font" style={{ marginLeft: '5px' }}>Resume</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/contact"
                onClick={() => updateExpanded(false)}
              >
                <MdContacts style={{ marginBottom: "2px" }} className="navbar_font"/> <span className="navbar_font" style={{ marginLeft: '5px' }}>Contact Me</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
            <DarkMode style={{ marginBottom: "2px" }}/>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
