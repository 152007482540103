import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  AiFillGithub,
  AiOutlineWhatsApp,
  AiOutlineYoutube,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Footer() {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <Container fluid className="footer">
      <Row>
        <Col md="4" className="footer-copywright">
          <h3 className="footer-things">Designed and Developed by Abhishek Srivastava</h3>
        </Col>
        <Col md="4" className="footer-copywright">
          <h3 className="footer-things">Copyright © {year}</h3>
        </Col>
        <Col md="4" className="footer-body">
          <ul className="footer-icons">
            <li className="social-icons">
              <a
                href="https://github.com/Abhishek143895"
                style={{ color: "white" }}
                target="_blank"
                className="footer-things"
                rel="noopener noreferrer"
              >
                <AiFillGithub />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://www.youtube.com/@abhisheksrivastava7999"
                style={{ color: "white" }}
                target="_blank"
                className="footer-things"
                rel="noopener noreferrer"
              >
                <AiOutlineYoutube />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://www.linkedin.com/in/abhishek-srivastava-933b6a19a/"
                style={{ color: "white" }}
                target="_blank"
                className="footer-things"
                rel="noopener noreferrer"
              >
                <FaLinkedinIn />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://wa.me/9140896307?text=Abhishek"
                style={{ color: "white" }}
                target="_blank"
                className="footer-things"
                rel="noopener noreferrer"
              >
                <AiOutlineWhatsApp />
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
