import emailjs from '@emailjs/browser';
import React, { useRef, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import '../../style.css';
import Particle from "../Particle";
import './Contact.css';
import Social from './Social';

const Contact = () => {
    const form = useRef();
    const [done, setDone] = useState('');
    const [formData, setFormData] = useState({});
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setDone('');
        setErrorMessage('');
    }

    const sendEmail = (e) => {
        e.preventDefault();
        if (!formData.from_name || !formData.reply_to || !formData.message) {
            setErrorMessage("Please fill all the fields.");
            return;
        }

        emailjs.sendForm(
            'service_mh4qdiq', // service key from emailjs
            'template_lmkqgwu',// template key
            form.current,
            'NF-ReIuqjQS3QzOKA' //public key from emailjs
        ).then(
            (result) => {
                // console.log(result.text);
                setDone(`Thank you for reaching out to me! ${formData.from_name} I've received your message and wanted to confirm that your enquiry has been successfully submitted. I'll be reviewing your message and will get back to you as soon as possible. If you have any urgent queries or additional information to provide, please feel connect with me on Linkdin. I appreciate your interest in enquiry and look forward to assisting you further.`);
                form.current.reset();
                setFormData({});
                setErrorMessage('');
                setTimeout(() => {
                    setDone('');
                }, 15000);
            },
            (error) => {
                console.log(error.text);
                setErrorMessage("Failed to send message. Please try again.");
            }
        );
    };

    return (
        <Container style={{ paddingTop: '120px' }} className='contact-us-page'>
            <Particle />
            <Row>
                <Col md={6} className="c-left">
                    <h1>Get in Touch</h1>
                    <br />
                    <h1 className="yellow">Contact me</h1>
                    <p>abhisheksrivastavabhu@gmail.com</p>
                </Col>
                <Col md={6} className="c-right">
                    <form ref={form} onSubmit={sendEmail}>
                        <input type="text" name="from_name" className="user" placeholder="Name*" onChange={handleChange} />
                        <input type="email" name="reply_to" className="user" placeholder="Email*" onChange={handleChange} />
                        <textarea name="message" className="user" placeholder="Message*" onChange={handleChange} />
                        {errorMessage && <span className='not-done'>{errorMessage}</span>}
                        <Button type="submit" className="button" disabled={Boolean(done)}>Send</Button>
                        {done && <span className='done'>{done}</span>}
                    </form>
                </Col>
            </Row>
            <Social />
        </Container>
    );
};

export default Contact;